import logo from './logo.svg';
import imgTest from "./media/vb.jpg"
import imgLogo from "./media/site logo.png"
import * as React from "react";
import { Link } from "react-router-dom";
import { FaTools, FaLocationArrow ,FaHandsHelping,FaCamera,FaMusic,FaPhotoVideo,FaWhatsappSquare,FaBars,FaFacebook,FaMailBulk,FaInstagramSquare,FaTwitter} from 'react-icons/fa';
import './App.css';
import './GridInfos.css';
import './GridTarifs.css';
import './Contact.css';
import './VersionTelephone.css';

function App() {
  return (
    <div className="App">
      <img id="imgHeader" src={imgTest}/>
      
      <img id="logo" src={imgLogo}/>
      <div id="titleDiv">
        <p id="accroche">Ajouter la toûche d'innovations qui manque à vos évènements !</p>
        
        <div id="video">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/trPOElkPQs8??modestbranding=1&autoplay=1&controls=0&wmode=transparent&hd=1&rel=0&autohide=1&showinfo=0&loop=1&playlist=trPOElkPQs8&enablejsapi=1&widgetid=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </div>
        <div id="description">
          <p className="title">
            Qu'est ce qu'une videobooth ?
          </p>
          <p>
            Un videobooth est un espace photo divertissant pour tous vos événements. 
            Il capture des moments inoubliables en vidéo et en photo, avec des options de 
            personnalisation, des filtres et des arrière-plans. 
            Les invités peuvent partager facilement leurs souvenirs sur les réseaux. Louez notre videobooth pour ajouter une touche de 
            plaisir à votre mariage, fête d'anniversaire, événement corporatif, et autres !
          </p>
        </div>
      </div>

      <div className="separatingBar"/>

      <div id="gridInfos">

        <FaTools className="icon" />
        <FaLocationArrow className="icon" />
        <FaHandsHelping className="icon" />
        <p>Installation, désinstallation</p>
        <p>30km autour de lyon (sur devis)</p>
        <p>1 coordinateur par animation</p>
        <FaPhotoVideo className="icon" />
        <FaCamera className="icon" />
        <FaMusic className="icon" />
        <p>Session vidéo illimitée</p>
        <p>Gopro 11 (slow motion) HD - 4K</p>
        <p>Filtres personnalisés</p>
        <FaPhotoVideo className="icon" />
        <FaWhatsappSquare className="icon" />
        <FaBars className="icon" />
        <p> Musiques personnalisés</p>
        <p>Envoi des vidéos par : QR Code, WhatsApp, E-mail</p>
        <p>Tapis rouge, Barrière de sécurité</p>

      </div>

<div className="separatingBar"/>

      

      <div id="gridTarifs">

        <div id="tarif2h">
        <p className="titre">Classic Flow</p>
        <p>Temps - 2H00</p>
        <p>Prix - 390€</p>
        </div>
        
        <div id="tarifLibre">
          
        <p className="titre">Ultra Flow</p>
        <p>Temps - 3H00</p>
        <p>Prix - 490€</p>
        </div>


        
        <div id="tarif3h">
        <p className="titre">Perso Flow</p>
        <p>Louer la machine uniquement</p>
        <p>Uniquement sur devis</p>
        </div>

        
        
        <div id="options">
          
        <p className="titre">Options</p>
          <p>Pistolet à bulles ou à billets - 10€</p>

          <p>Heures supplémentaires - 140€</p>


        </div>

      </div>
      <Link to="reservation" style={{ textDecoration: 'none' }}>
      <div id="boutonReservation">
        <p>Réserver</p>
      </div>
      </Link>

      <div id="contactFooter">
      <p id="contactTitre">Contactez-moi !</p>
      <div id="gridContact">
        <a href="mailto:videoboothbyflow360@hotmail.com">
        <FaMailBulk className="icon"/> <p>Mail</p></a>
        <a href="https://www.facebook.com/profile.php?id=100085122895601">
        <FaFacebook className="icon"/> <p>Facebook</p></a>
        <a href="https://www.instagram.com/flow360videobooth/?hl=fr">
        <FaInstagramSquare className="icon"/> <p>Instagram</p></a>
        <a href="https://mobile.twitter.com/flow360video">
        <FaTwitter className="icon"/> <p>Twitter</p></a>
      </div>
      </div>
    </div>
  );
}

export default App;
