
import * as React from "react";
import { useRef } from 'react';
import { Link } from "react-router-dom";
import './Reservation.css';
import imgLogo from "./media/site logo.png"
import imgTest from "./media/headerresa.jpg"
import emailjs from '@emailjs/browser';


function Reservation() {

    
const form = useRef();
const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_uuzf0j6', 'template_zggqlxi', form.current, 'WjKTDn8PuVffqFmK7')
      .then((result) => {
          console.log(result.text);
          alert("Votre réservation a été envoyée.");
      }, (error) => {
          console.log(error.text);
          alert("Il y a eu une erreur : votre réservation n'a pas pu être envoyée. Contactez-moi !");
      });
  };
  return (
  
  <div id="reservation">
    <img id="header" src={imgTest}/>
    <Link to="/"><img id="logo" src={imgLogo}/></Link>
        {/* <Link to="/" id="retouraccueil">Retour Accueil</Link> */}
    <div id="contenu">
  <form action="javascript:void(0)" ref={form} onSubmit={sendEmail}>
  <div class="elem-group">
    <label for="name">Votre Nom</label>
    <input type="text" id="name" name="visitor_name" placeholder="Prénom Nom" required/>
  </div>
  <div class="elem-group">
    <label for="email">Votre E-mail</label>
    <input type="email" id="email" name="visitor_email" placeholder="votreadresse@email.com" required/>
  </div>
  <div class="elem-group">
    <label for="phone">Votre Numéro de Téléphone</label>
    <input type="tel" id="phone" name="visitor_phone" placeholder="07-00-00-00-00" required/>
  </div>
  <hr/>
  <div class="elem-group">
    <label for="checkin-date">Date de réservations</label>
    <input type="date" id="checkin-date" name="checkin" required/>
  </div>
  <div class="elem-group">
    <label for="room-selection">Votre formule</label>
    <select id="room-selection" name="room_preference" required>
        <option value="">Choisissez une option</option>
        <option value="Classic Flow">Classic Flow</option>
        <option value="Ultra Flow">Ultra Flow</option>
        <option value="Perso Flow">Perso Flow</option>
    </select>
  </div>
  <hr/>
  <div class="elem-group">
    <label for="message">Votre besoin</label>
    <textarea id="message" name="visitor_message" placeholder="Options supplémentaires, type d'évènements, autres informations..." required></textarea>
  </div>
  <button type="submit" onClick={sendEmail}>Réserver votre VideoBooth</button>

</form>
</div>
</div>);
}
export default Reservation;